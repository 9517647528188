import { graphql } from "gatsby";
import React from "react";
import { Col, Container, Image, Pagination, Row } from "react-bootstrap";
import FormattedDate from "../components/text/FormattedDate";
import StandardPageContainer from "../components/layout/StandardPageContainer";
import Main from "../static-components/html/Main";
import { Link } from "gatsby";

export default function BlogList({data}){
    return (
        <Main title="Blog">
            <StandardPageContainer>
                <Container>
                    <Row className="mb-3">
                        {data.allContentfulBlog.nodes.map((post) =>  (
                            <Col key={post.contentful_id} lg={6}>
                                <Link to={"/blog/" + post.url} className="link-no-decoration">
                                    <Container className="px-4 py-3 shadow-hover rounded-5">
                                        <Row>
                                            <Col className="py-3">
                                                <Image className="image-cover" src={post.image.file.url} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="py-1">
                                                <h4 className="h-text">{post.title}</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="">
                                                <p className="body-text">{post.subtitle}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="py-1">
                                                <span className="text-strong body-text">{post.author}</span><br/>
                                                <span className="text-muted"><FormattedDate>{post.date}</FormattedDate></span>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Link>
                            </Col>
                        ))}
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Pagination size="md">
                            <Pagination.First 
                                href="/blog"
                                disabled={data.allContentfulBlog.pageInfo.currentPage === 1}/>
                            <Pagination.Prev 
                                href={data.allContentfulBlog.pageInfo.currentPage === 2 ? "/blog" : `/blog/${data.allContentfulBlog.pageInfo.currentPage - 1}`}
                                disabled={!data.allContentfulBlog.pageInfo.hasPreviousPage}/>
                            {Array.from({ length: data.allContentfulBlog.pageInfo.pageCount}).map((_, index) => (
                                <Pagination.Item 
                                    key={index} 
                                    href={index === 0 ? "/blog" : `/blog/${index + 1}`}
                                    active={index === data.allContentfulBlog.pageInfo.currentPage - 1}>
                                        {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next 
                                href={`/blog/${data.allContentfulBlog.pageInfo.currentPage + 1}`}
                                disabled={!data.allContentfulBlog.pageInfo.hasNextPage}/>
                            <Pagination.Last 
                                href={`/blog/${data.allContentfulBlog.pageInfo.totalCount}`}
                                disabled={data.allContentfulBlog.pageInfo.currentPage === data.allContentfulBlog.pageInfo.totalCount}/>
                        </Pagination>
                    </Col>
                </Row>
            </Container>
            </StandardPageContainer>
        </Main>
    )
}

export const blogListQuery = graphql`
    query blogListQuery($skip: Int!, $limit: Int!) {
        allContentfulBlog(limit: $limit, skip: $skip, sort: {fields: date, order: DESC}) {
        pageInfo {
            currentPage
            hasNextPage
            hasPreviousPage
            itemCount
            pageCount
            perPage
            totalCount
        }
        totalCount
        nodes {
            title
            subtitle
            author
            image {
            file {
                url
            }
            }
            blog {
            raw
            }
            contentful_id
            date
            url
        }
        }
    }
`