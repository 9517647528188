import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../buttons/BackButton";

export default function StandardPageContainer({backButton = false, backButtonComponent = null, title = null, navbarComponent = null, navAboveTitle = true, headerRHSComponent = null, ...props}){
    return(
        <Container xs={12} className="pt-3 px-0">
            {navbarComponent && navAboveTitle && (
                <Row className="pb-3 my-3 px-3">
                    <Col md={12}>
                        {navbarComponent}
                    </Col>
                </Row>
            )}
            <Row className={(title || headerRHSComponent) ? "pb-3 mt-4 mb-3 border-bottom px-3" : ""}>
				<Col md={headerRHSComponent ? 6 : 12} className="text-left">
                    {backButton && !backButtonComponent && (<BackButton text="Go back"/>)}
                    {!backButton && (backButtonComponent)}
                    {title && (<h2>{title}</h2>)}
                </Col>
				{headerRHSComponent && (<Col md={6} className="text-end pt-2">
					{headerRHSComponent}
				</Col>)}
			</Row>
            {navbarComponent && !navAboveTitle && (
                <Row className="pb-3 my-3 ps-3">
                    <Col md={12}>
                        {navbarComponent}
                    </Col>
                </Row>
            )}
			<Row>
				<Col md={12}>
                    {props.children}
				</Col>
			</Row>
        </Container>
    )
}